<template>
   <section class="mb-10 solutions-holder">   
            <v-layout justify-space-around justify-center>
                <v-flex xs9 sm9 offset-sm1 class="">
                     <v-card flat tile color="" width="90%" class="transparent main-content-products text-center mt-4">
                        <v-card-title primary-title class="justify-center">
                            <h3 v-if="!is_screen_small" class="zamu-heading transact-heading product-heading solutions-heading">Our Solution</h3>
                            <h3 v-if="is_screen_small" class="zamu-heading transact-heading product-heading solutions-heading">Our <br /> Solution</h3>

                            <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center transact-underline mt-3">
                                <v-card-text class="transact-divider divider-purple"></v-card-text>
                                <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                            </v-card>
                        </v-card-title>
                     </v-card>

                       <v-row no-gutters v-for="(section, index) in sectionInfo" :key="index" :class="{'reverse-div': section.reverse}" class="content-holder ">
                                    <v-col lg="6" md="6" sm="12" xs="12" class="pull-right  py-0" >
                                       <v-card color="transparent" flat tile height="100%" align="end" class="content-image-holder   py-0" >
                                           <v-card-text align="center" justify="center" class="py-0">
                                           <v-img :src="require(`../../../assets/images/${section.image}`)" class="content-image" :class="section.imgClass"></v-img>
                                           </v-card-text>

                                       </v-card>
                                    </v-col>
                                    <v-col lg="6" md="6" sm="12" xs="12" class="pull-left" >

                                         <v-row v-for="(content, i) in section.content" :key="i" class="mb-10">
                                            <v-col lg="10" md="10" sm="12" xs="12">
                                               <div class="content-title mb-3"> {{ content.header }} </div>
                                               <div class="content-text" v-html="content.text">
                                               </div>
                                            </v-col>
                                            <template v-if="content.child">
                                             <v-col cols="12" lg="12" md="12" sm="12" xs="12"  v-for="(child, index) in content.child" :key="index" class="">
                                                <v-row class="">
                                                    <v-col cols="12" lg="2" md="2" sm="12" xs="12" align="center" justify="center">
                                                        <div class="content-number mb-3"> {{ child.number }} </div>
                                                        <v-divider :color="`${zamuYellow3}`" class="icon-divider top-divider"></v-divider>
                                                        <v-divider :color="`${zamuYellow3}`" class="icon-divider"></v-divider>
                                                    </v-col>
                                                    <v-col cols="12" lg="10" md="10" sm="12" xs="12" class="px-2">
                                                    <div class="content-text mx-2" v-html="child.text">
                                                    </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            </template>
                                        </v-row>
                                    </v-col>
                               
                            </v-row>
                </v-flex>
            </v-layout>
   </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: 'Story',
    mixins: [colorMixin, coreMixin],

    computed : {
    
          sectionInfo () {
            const data = [
                {
                    section: 1,
                    reverse: true,
                    image: 'story.png',
                    imgClass: 'scaled-img',
                    content: [
                        {
                            number: '01',
                            header : '',
                            text: `
                                <p>
                                 Existing market research indicates that, consumers are more likely to respond to SMSs compared to other messaging formats.  The Zamupay Bulk SMS service is a simple and ideal solution that offers businesses a fast and efficient means to push specific messages to various audiences and stakeholder clusters, i.e. staff, prospective and existing clients. 
                                </p>
                              
                            `
                        
                        }
                    ]
                },
                {
                    section: 2,
                    reverse: false,
                    image: 'story.png',
                    imgClass: 'scaled-img',
                    content: [
                        {
                            number: '03',
                            header : 'The prerequisites to onboarding',
                            text: `
                           <p>
                              The targeted stakeholders or intended recipients of the message/s must:
                            </p>
                            
                            `,
                            child: [
                                {
                                    number: '01',
                                    text: 'Have subscribed to the services of a mobile telecommunications company.'
                                },
                                {
                                    number: '02',
                                    text: 'Be registered owners of a mobile line.'
                                },
                                {
                                    number: '03',
                                    text: 'Be geo-located in an area served by the relevant telecommunication companies.'
                                },
                            ]
                        }
                    ],
                  
                }
            ];
            return data;
        },

    },


}
</script>

<style>
 .content-title {
     color: #43237d;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
 }
 .content-text {
    color: #727272;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
 }
 .content-image-holder {
    position: relative;

 }
.content-image {
    margin-bottom: 6em;

}
.content-holder {
    margin-bottom: 24px;
}
.scaled-img {
     width: 75%;
}
.content-number {
    color: #d29f2a;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.55px;
}
</style>